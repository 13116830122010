import React from 'react';
import '../../styles/app.global.scss';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductPageAppProps} from '../../types/app-types';
import {ClassicLayout} from '../Layouts/ClassicLayout/ClassicLayout';
import {QuickViewLayout} from '../Layouts/QuickViewLayout/QuickViewLayout';
import {MobileLayout} from '../Layouts/MobileLayout/MobileLayout';
import {SimpleLayout} from '../Layouts/SimpleLayout/SimpleLayout';
import {SleekLayout} from '../Layouts/SleekLayout/SleekLayout';
import {SpotlightLayout} from '../Layouts/SpotlightLayout/SpotlightLayout';
import {StunningLayout} from '../Layouts/StunningLayout/StunningLayout';
import {LayoutId} from '../../constants';
import s from './ProductPageApp.scss';
import {getLayoutIdFromProps, getStyleParamsFromProps} from '../../commons/styleParamsService';
import cx from 'classnames';

const layouts = {
  [LayoutId.Classic]: ClassicLayout,
  [LayoutId.Mobile]: MobileLayout,
  [LayoutId.QuickView]: QuickViewLayout,
  [LayoutId.Simple]: SimpleLayout,
  [LayoutId.Sleek]: SleekLayout,
  [LayoutId.Spotlight]: SpotlightLayout,
  [LayoutId.Stunning]: StunningLayout,
};

const layoutsNames = {
  [LayoutId.Classic]: 'classic',
  [LayoutId.Mobile]: 'mobile',
  [LayoutId.QuickView]: 'quickview',
  [LayoutId.Simple]: 'simple',
  [LayoutId.Sleek]: 'sleek',
  [LayoutId.Spotlight]: 'spotlight',
  [LayoutId.Stunning]: 'stunning',
};

export const LayoutContext = React.createContext('');

@withGlobalProps
export class ProductPageApp extends React.Component<ProductPageAppProps> {
  constructor(props) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  private renderEmpty() {
    return (
      <h1 data-hook="empty-state" className={s.emptyState}>
        Product Not Found
      </h1>
    );
  }

  private renderProduct() {
    const {product, isDesktop, experiments} = this.props.globals;
    const layoutId: LayoutId = getLayoutIdFromProps(this.props);
    const settings = getStyleParamsFromProps(this.props);
    const LayoutComp = layouts[layoutId];
    const layoutName = layoutsNames[layoutId];
    const isMobile = layoutName === layoutsNames[LayoutId.Mobile];
    const SSRImageImprovements = experiments && experiments.isSSRImageImprovements;
    const newMobileLayout = experiments && experiments.isNewMobileLayout;
    const classNames = cx(s.ProductPageApp, {
      isMobile,
      [s.mobile]: isMobile,
      isDesktop,
      newMobileLayout,
      SSRImageImprovements,
    });

    return (
      <LayoutContext.Provider value={layoutName}>
        <div data-hook="product-page" className={classNames}>
          <LayoutComp
            data-hook="product-layout"
            product={product}
            settings={settings}
            globals={this.props.globals}
            {...this.props}
          />
        </div>
      </LayoutContext.Provider>
    );
  }

  public componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  private reportAppLoaded() {
    const {
      globals: {
        experiments: {isFedOpsInteractive},
        onAppInteractive,
        onAppLoaded,
      },
    } = this.props;
    if (isFedOpsInteractive) {
      onAppInteractive();
    } else {
      this.safeRun(onAppLoaded);
    }
  }

  private safeRun(fn) {
    if (this.props.globals.isInteractive && typeof fn === 'function') {
      fn();
    }
  }

  public render() {
    const {emptyState} = this.props;
    return emptyState ? this.renderEmpty() : this.renderProduct();
  }
}
