import * as React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import SlickSlider from 'react-slick';
import {IVideoPlayer} from '../../../types/app-types';
import classNames from 'classnames';
import s from './ProductGalleryProvider.scss';
import {getMainImageRatio} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import _ from 'lodash';
import '../slick-carousel.global.scss';
import {IProductMediaItem} from '@wix/wixstores-graphql-schema/dist/es/src';
import {IMedia} from '../../../types/productDef';
import {imageMobileWidth} from '../../../constants';

export interface ProductGalleryProviderProps extends ProvidedGlobalProps {
  imageRatioId: number;
  media: IProductMediaItem[];
  withImageRatio: boolean;
  mediaSignature?: string;
}

interface ProductGalleryProviderState {
  selectedIndex: number;
  mainMediaSlider: SlickSlider;
  videoPlayers: Record<number, IVideoPlayer>;
}

export interface IProductGalleryContext {
  selectedIndex: number;
  registerVideoPlayer(player: IVideoPlayer, index: number): void;
  changeSelectedIndex(selectedIndex: number): void;
  setMainMediaSlider(mainMediaSlider: SlickSlider): void;
  mainMediaSlider: SlickSlider;
}

export const ProductGalleryContext = React.createContext({
  selectedIndex: 0,
  registerVideoPlayer: _.noop,
  changeSelectedIndex: _.noop,
  setMainMediaSlider: _.noop,
  mainMediaSlider: {} as SlickSlider,
} as IProductGalleryContext);

@withGlobalProps
export class ProductGalleryProvider extends React.Component<ProductGalleryProviderProps, ProductGalleryProviderState> {
  public state = {
    selectedIndex: 0,
    mainMediaSlider: null,
    videoPlayers: {},
  };

  constructor(props: ProductGalleryProviderProps) {
    super(props);
  }

  public componentDidUpdate(
    prevProps: Readonly<ProductGalleryProviderProps>,
    _prevState: Readonly<ProductGalleryProviderState>
  ): void {
    if (prevProps.mediaSignature !== this.props.mediaSignature && this.props.media.length > 1) {
      this.changeSelectedIndex(0);
    }
  }

  private readonly registerVideoPlayer = (player: IVideoPlayer, index: number) => {
    const {videoPlayers} = this.state;
    videoPlayers[index] = player;
    this.setState({videoPlayers});
  };

  private readonly changeSelectedIndex = (newlySelectedIndex: number) => {
    const {videoPlayers, mainMediaSlider} = this.state;
    if (videoPlayers[newlySelectedIndex]) {
      videoPlayers[newlySelectedIndex].pause();
    }
    this.setState({selectedIndex: newlySelectedIndex});
    mainMediaSlider.slickGoTo(newlySelectedIndex);
    if (videoPlayers[newlySelectedIndex]) {
      videoPlayers[newlySelectedIndex].play();
    }
  };

  private readonly setMainMediaSlider = (mainMediaRef: SlickSlider) => {
    const {mainMediaSlider} = this.state;
    if (mainMediaRef && mainMediaSlider !== mainMediaRef) {
      this.setState({mainMediaSlider: mainMediaRef});
    }
  };

  private readonly getImageRationClass = (media: IProductMediaItem, imageRatioId: number) => {
    const ratioClassSuffix = getMainImageRatio(media as IMedia, imageRatioId).title.replace(':', '-');
    return `image-ratio-${ratioClassSuffix}`;
  };

  private readonly getComposite = () => {
    const {
      children,
      withImageRatio,
      media,
      imageRatioId,
      globals: {
        product,
        experiments: {isSSRImageImprovements},
        isMobile,
      },
    } = this.props;
    const keyMedia = media ? media[0] : null;
    const imageRatio = this.getImageRationClass(keyMedia, imageRatioId);
    const productGalleryComposite = classNames(
      {[s.productGallery]: withImageRatio},
      {[s.withImageRatio]: withImageRatio && !(isSSRImageImprovements && isMobile)},
      {[s[imageRatio]]: withImageRatio && !(isSSRImageImprovements && isMobile)},
      {[s.productGallery]: !withImageRatio}
    );
    const height =
      isSSRImageImprovements && isMobile
        ? product.media.length > 0
          ? (imageMobileWidth * product.media[0].height) / product.media[0].width
          : 0
        : null;
    return (
      <div data-hook="product-gallery-composite" className={productGalleryComposite} style={{height}}>
        {children}
      </div>
    );
  };

  public render() {
    const {media} = this.props;
    const {selectedIndex, mainMediaSlider} = this.state;

    return media ? (
      <ProductGalleryContext.Provider
        value={{
          selectedIndex,
          mainMediaSlider,
          registerVideoPlayer: this.registerVideoPlayer,
          changeSelectedIndex: this.changeSelectedIndex,
          setMainMediaSlider: this.setMainMediaSlider,
        }}>
        {this.getComposite()}
      </ProductGalleryContext.Provider>
    ) : (
      this.getComposite()
    );
  }
}
