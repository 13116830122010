import React from 'react';
import cx from 'classnames';
import s from './ProductPrice.scss';
import {withDirection, withGlobalProps} from '../../../providers/globalPropsProvider';
import {LayoutContext} from '../../ProductPageApp/ProductPageApp';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface ProductPriceProps {
  price: number;
  comparePrice?: number;
  formattedPrice: string;
  formattedComparePrice?: string;
  className?: string;
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductPrice extends React.Component<ProductPriceProps & IProvidedTranslationProps> {
  private renderPrimary() {
    const {formattedPrice, comparePrice, t} = this.props;

    const primaryTranslationKey = comparePrice
      ? 'SR_PRODUCT_PRICE_AFTER_DISCOUNT'
      : 'SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT';

    return (
      <>
        <span data-hook="formatted-primary-price" className={s.primary}>
          {formattedPrice}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(primaryTranslationKey)}
        </span>
      </>
    );
  }

  private renderSecondary() {
    const {formattedComparePrice, t} = this.props;

    return (
      <>
        <span data-hook="formatted-secondary-price" className={s.secondary}>
          <span>{formattedComparePrice}</span>
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('SR_PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </>
    );
  }

  public render() {
    const {price, comparePrice, className} = this.props;

    if (!price) {
      return null;
    }

    return (
      <LayoutContext.Consumer>
        {layoutName => (
          <div data-hook="product-price-wrapper" className={cx(className, s.prices, s[`prices__${layoutName}`])}>
            {comparePrice > 0 && this.renderSecondary()}
            {this.renderPrimary()}
          </div>
        )}
      </LayoutContext.Consumer>
    );
  }
}
